import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

interface ResidentialAssessment {
  "id": number;
  "name": string;
  "parent_id": number;
  "status": string;
}
interface Row {
  date: string;
  amount: string;
  subamount: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  active?: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  expanded: boolean;
  expandedCapitalGains: boolean,
  expandedIncomeFromOtherSources: boolean,
  expandedDeductions: boolean,
  open: boolean
  //capital gains
  short_term_capital_gains_value1: string,
  short_term_capital_gains_value2: string,
  short_term_capital_gains_value3: string,
  short_term_capital_gains_value4: string,

  stcgCovered1: string,
  stcgCovered2: string,
  stcgCovered3: string,
  stcgCovered4: string,

  long_term_capital_gains_value1: string,
  long_term_capital_gains_value2: string,
  long_term_capital_gains_value3: string,
  long_term_capital_gains_value4: string,

  ltcg10_value1: string,
  ltcg10_value2: string,
  ltcg10_value3: string,
  ltcg10_value4: string,
  //income from other source
  interest: string,
  commision: string,
  crossword: string,
  // total inside captal gains
  total1: string,
  total2: string,
  total3: string,
  total4: string,

  //Deductions DropDown
  LiftInsurancePremiumPaid: string,
  PaymentForAnnuityPlan: string,
  ContributionTowardProvidentFundPPF: string,
  InvestmentInNSC: string,
  ContributionTowardsULIP: string,
  ContributionTowardsUTI: string,
  Re_PaymentOfHousingLoan: string,
  TuitionFees: string,
  ContributionTowardsNPF: string,
  EmployeeContributionNPS: string,
  AdditionalContributionNPS: string,
  InfrastructureBonds: string,
  EquitySavingScheme: string,
  SukanyaSamridhiDeposit: string,
  TotaInsideDeduction: string,
  MediClaimPremium: string,
  MedicalTreatmentPayment: string,
  Donations80G: string,
  DeductionForMaintenance: string,
  LoanForHigherEducation: string,
  LoanForResidentialHouse: string,
  DisabilityDeduction: string,
  InterestOnSavings: string,
  AgnipathSchemeContribution: string,
  OtherDeductions: string,

  assessmentYear: string;
  assessmentYearError: boolean;
  taxPayer: string;
  taxPayerError: boolean;
  gender: string;
  genderError: boolean;
  residentialStatus: string;
  residentialStatusError: boolean;
  incomeFromSalaryError: boolean;
  incomeFromSalary: string;
  incomeHouseProperty: string;
  interestOnHousing: string;
  incomeFromSelfHousePropery: string;
  annualLetable: string,
  municipalTaxPaid: string,
  unrealisedRent: string,
  netAnnualValue: string,
  standardDeduction: string,
  FixedDeposit: string,
  EmployerContributionNPS: string,
  OtherDeductible80C: string,


  incomeFromLetoutHouseProperty: string,
  capitalGains: string,
  incomeFromOtherSource: string,
  profitAndGains: string,
  agricultureIncome: string,
  deduction: string,
  netTaxableIncome: string,
  normalRate1: string,
  normalRate2: string,
  shortTermCapitalGains: string,
  shortTermCapitalGains2: string,
  longTernCapitalGains: string,
  longTernCapitalGains2: string,
  longTermAt10: string,
  longTermAt102: string,
  winningLottery: string,
  winningLottery2: string,
  incomeTax: string,
  surcharge: string,
  educationCess: string,
  secondaryAndHighEducationCesss: string,
  totalTaxLiabitlity: string,
  dueDate: string,
  actualDate: string,
  relief: string,
  tds: string,
  taxPaidDetail: { date: string, amount: string, subamount: string }[]
  A234: string,
  B234: string,
  C234: string,
  interestOnHousingLoan: string,
  residentialQuestion:[ResidentialAssessment],
  toast: {
    type: "success" | "info" | "warning" | "error";
    open: boolean;
    message: string;
};
  isResident: string
  HRA_basicSalary: string,
  HRA_DAFormingPartOfSalary: string,
  HRA_CommissionTurnoverAchieved: string,
  HRA_HraRecieved: string,
  HRA_RentPaid: string,
  HRA_residingInMetro: boolean,
  HRA_ExemptedHouseRent: string,
  HRA_TaxableHouseRent: string,
  openStatus: boolean,
  AssesmentDone:boolean,

  //capitalgain calc
  YOS:string,
  saleC:string, 
  transferExp:string,
  COA:string,
  COI:string,
  assessedTax:string,
  TSV:string,
  totalCost:string,
  saleValue:string,
  purchasedVal:string,
  periodOfHolding:string,
  capitalGainType:string

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmenttestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
   getResidentialCalculatorCallID: string = ""
   getPrevResidentialCalculatorCallID: string = ""
   getResidentialQuizAPIcallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.CountryCodeMessage),
        getName(MessageEnum.RestAPIResponceDataMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        getName(MessageEnum.RestAPIRequestMethodMessage),
        getName(MessageEnum.RestAPIRequestMessage),
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,

      // Customizable Area Start
      expanded: false,
      expandedCapitalGains: false,
      expandedIncomeFromOtherSources: false,
      expandedDeductions: false,
      open: false,

      //capital gains
      short_term_capital_gains_value1: "",
      short_term_capital_gains_value2: "",
      short_term_capital_gains_value3: "",
      short_term_capital_gains_value4: "",

      stcgCovered1: "",
      stcgCovered2: "",
      stcgCovered3: "",
      stcgCovered4: "",

      long_term_capital_gains_value1: "",
      long_term_capital_gains_value2: "",
      long_term_capital_gains_value3: "",
      long_term_capital_gains_value4: "",

      ltcg10_value1: "",
      ltcg10_value2: "",
      ltcg10_value3: "",
      ltcg10_value4: "",
      // income from other sources
      interest: "",
      commision: "",
      crossword: "",

      // totals inside capital gains
      total1: "0",
      total2: "0",
      total3: "0",
      total4: "0",

      // Deductions DropDown
      LiftInsurancePremiumPaid: "",
      PaymentForAnnuityPlan: "",
      ContributionTowardProvidentFundPPF: "",
      InvestmentInNSC: "",
      ContributionTowardsULIP: "",
      ContributionTowardsUTI: "",
      Re_PaymentOfHousingLoan: "",
      TuitionFees: "",
      FixedDeposit: "",
      ContributionTowardsNPF: "",
      EmployeeContributionNPS: "",
      AdditionalContributionNPS: "",
      EmployerContributionNPS: "",
      InfrastructureBonds: "",
      EquitySavingScheme: "",
      SukanyaSamridhiDeposit: "",
      OtherDeductible80C: "",
      TotaInsideDeduction: "",
      MediClaimPremium: "",
      MedicalTreatmentPayment: "",
      Donations80G: "",
      DeductionForMaintenance: "",
      LoanForHigherEducation: "",
      LoanForResidentialHouse: "",
      DisabilityDeduction: "",
      InterestOnSavings: "",
      AgnipathSchemeContribution: "",
      OtherDeductions: "",

      assessmentYear: "",
      assessmentYearError: false,
      taxPayer: "",
      taxPayerError: false,
      gender: "",
      genderError: false,
      residentialStatus: "",
      residentialStatusError: false,
      incomeFromSalaryError: false,
      incomeFromSalary: "",
      incomeHouseProperty: "",
      interestOnHousing: "",
      incomeFromSelfHousePropery: "",
      annualLetable: "",
      municipalTaxPaid: "",
      unrealisedRent: "",
      netAnnualValue: "",
      standardDeduction: "",

      incomeFromLetoutHouseProperty: "",
      capitalGains: "",
      incomeFromOtherSource: "",
      profitAndGains: "",
      agricultureIncome: "",
      deduction: "",
      netTaxableIncome: "",
      normalRate1: "",
      normalRate2: "",
      shortTermCapitalGains: "",
      shortTermCapitalGains2: "",
      longTernCapitalGains: "",
      longTernCapitalGains2: "",
      longTermAt10: "",
      longTermAt102: "",
      winningLottery: "",
      winningLottery2: "",
      incomeTax: "",
      surcharge: "",
      educationCess: "",
      secondaryAndHighEducationCesss: "",
      totalTaxLiabitlity: "",
      dueDate: "",
      actualDate: "",
      relief: "",
      tds: "",
      taxPaidDetail: [{ date: '', amount: '', subamount: ''}],
      A234: "",
      B234: "",
      C234: "",
      interestOnHousingLoan: "",
      isResident: "",
      residentialQuestion:[{
        "id": 0,
        "name": "",
        "parent_id": 0,
        "status": ""
      }],
      toast: {
        type: "success",
        open: false,
        message: "",
      },

      HRA_basicSalary: "",
      HRA_DAFormingPartOfSalary: "",
      HRA_CommissionTurnoverAchieved: "",
      HRA_HraRecieved: "",
      HRA_RentPaid: "",
      HRA_residingInMetro: false,
      HRA_ExemptedHouseRent: "",
      HRA_TaxableHouseRent: "",
      openStatus: false,
      AssesmentDone:false,
      //capitalgain calc
      YOS:"",
      saleC:"", 
      transferExp:"",
      COA:"",
      COI:"",
      assessedTax:"",
      TSV:"",
      totalCost:"",
      saleValue:"",
      purchasedVal:"",
      periodOfHolding:"",
      capitalGainType:""
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      if (
        this.getResidentialCalculatorCallID ===  apiCallId
      ) {
        this.receiveResidentialCalResponse(message);
      }
      if (
        this.getPrevResidentialCalculatorCallID ===  apiCallId
      ) {
        this.receivePrevResidentialCalResponse(message);
      }
      if(this.getResidentialQuizAPIcallId == apiCallId){
         this.setState({
          isResident:"",
        toast: { open: true, message:"Resedential Status Updated successfully", type: "success" },
      });
      const isRedirect = await getStorageData("redirectToGI")
      if(isRedirect=="1"){
        removeStorageData("redirectToGI")
        setStorageData("residentStep","3")
        setTimeout(()=>{
          this.props.navigation.navigate(
            "Menu",
            { navigationBarTitleText: "General Information" }
          );
        },2000)
      }
      }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    this.getResidentialFirstQuestion()
    const pathname = window.location.pathname;

    if(pathname.includes("CapitalGainCalc")){
      const type = this.props.navigation.getParam("type")
      this.setState({
        capitalGainType:type
      })
    }
    // Customizable Area End
  }

   getEndPoint = (type: string | undefined, id: number | undefined, status: string | undefined) => {
    let apiEndPoint: string;
  
    const baseEndpoint = window.location.pathname.includes("Menu/Residential")
      ? configJSON.getResidentailCalculatorAPIEndpoint
      : configJSON.getLandingpageResidentialStatusAPIEndpoint 
    
  
    // Construct the full endpoint based on the type
    switch (type) {
      case "next":
        apiEndPoint = `${baseEndpoint}?parent_id=${id}&status=${status}`;
        break;
      case "prev":
        apiEndPoint = `${baseEndpoint}?parent_id=${id}`;
        break;
      default:
        apiEndPoint = baseEndpoint;
        break;
    }
  
    return apiEndPoint;
  };
  

  getResidentialFirstQuestion = async(queType?:string,id?:number,status?:string)=>{
    const headers = window.location.pathname.includes("Menu/Residential") ? {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData(configJSON.authToken)
    } : {
      "Content-Type": configJSON.validationApiContentType,
    };

      const getpasswordfrom = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getResidentialCalculatorCallID  = getpasswordfrom.messageId

      getpasswordfrom.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          this.getEndPoint(queType,id,status)
      );

      getpasswordfrom.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
      );

      getpasswordfrom.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType

      );
      runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
  }

  handleToastType = (name:string)=>{
    let toastType
    switch(name){
      case "NOT ORDINARILY RESIDENT":
        toastType = "info"
        break;
      case "RESIDENT":
        toastType = "warning"
        break;
      case "NON RESIDENT":
        toastType = "success"
        break;
    }
    return toastType as "success" | "info" | "warning" 
  }
  
  receiveResidentialCalResponse = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson.length > 0){
      const isStopAssessment = responseJson.some((value:{name:string})=>value.name=== "NOT ORDINARILY RESIDENT" || value.name==="RESIDENT" || value.name==="NON RESIDENT")
      if(isStopAssessment){
        this.setState({
          AssesmentDone:isStopAssessment
        //   toast: { open: true, message:responseJson[0].name, type: this.handleToastType(responseJson[0].name) },
        });
      }
      this.setState({residentialQuestion:responseJson,isResident:""})
    }
  }

  receivePrevResidentialCalResponse = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson){
      this.setState({residentialQuestion:[responseJson],isResident:responseJson.status},()=>{
       this.setState({
         toast:{
           type: "info",
           open: false,
           message: ""
          }
        })
      })
    }
  }

  handleNextQuestion = (value:ResidentialAssessment,type?:string)=>{

    if(type == "submit"){
      this.handlesubmitquiz()
    }

    else{
      if(this.state.isResident){
        this.getResidentialFirstQuestion("next",value.id,this.state.isResident)
      }
      else {
        this.setState({
          toast: { open: true, message:"Please select answer", type: "error" },
        });
      }
    }
  }

  handlegetstatus = (name:string) =>{
    if(name == "resident"){
      return "resident"
    }
    else if(name == "non resident"){
      return "non_resident"
    }
    else if (name == "not ordinarily resident"){
      return "not_ordinarily_resident"
    }
  }

  handlesubmitquiz = async() => {
    const headers = {
      "token": await getStorageData(configJSON.authToken)
  };

  const quiz = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getResidentialQuizAPIcallId  = quiz.messageId

  quiz.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ResidentialQuizAPIEndpoint}?residental_status=${this.handlegetstatus(this.state.residentialQuestion[0].name.toLocaleLowerCase())}`
  );

  quiz.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );

  quiz.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethodtype

  );
  runEngine.sendMessage(quiz.id, quiz);
  }
  checkIsResidentOrNot = () =>{
    const isStopAssessment = this.state.residentialQuestion.some((value:{name:string})=>value.name=== "NOT ORDINARILY RESIDENT" || value.name==="RESIDENT" || value.name==="NON RESIDENT")
    return isStopAssessment
  }

hanldePrevQuestionApiCall = async(id:number)=>{
  const headers = window.location.pathname.includes("Menu/Residential") ? {
    "Content-Type": configJSON.validationApiContentType,
    "token": await getStorageData(configJSON.authToken)
  } : {
    "Content-Type": configJSON.validationApiContentType,
  };

  const baseEndpoint = window.location.pathname.includes("Menu/Residential")
  ? configJSON.prevResidentailCalculatorAPIEndpoint
  : configJSON.LandingpageprevResidentailCalculatorAPIEndpoint 

  const prevQueForm = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getPrevResidentialCalculatorCallID  = prevQueForm.messageId

  prevQueForm.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${baseEndpoint}?id=${id}`
  );

  prevQueForm.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );

  prevQueForm.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType

  );
  runEngine.sendMessage(prevQueForm.id, prevQueForm);
}


  handlePreviousQuestion = (value:ResidentialAssessment)=>{
    this.setState({AssesmentDone:false})
      this.hanldePrevQuestionApiCall(value.id)
  }

  handleTostClose = () => {
      this.setState({
        toast: {
            open: false,
            message: this.state.toast.message,
            type: this.state.toast.type,
        },
        });
   
}

  handleLoginBtn = () => {
    this.props.navigation.history.push(configJSON.login)
  }

  handleLogout = () => {
    this.props.navigation.history.push(configJSON.login)
  }

  handleHeaderRoute = (path: string) => {
    this.props.navigation.history.push(path)
  }

  handlemodalopen = () => {
    this.setState({ open: true })
  }

  navigateToDashBoard = () => {
    localStorage.getItem("authToken") ? this.props.navigation.history.push(configJSON.navToDashboard) : this.props.navigation.history.push(configJSON.login)
  }
  handledashprofile = () => {
    this.props.navigation.history.push(configJSON.UserProfileBasicBlock)
  }
  handleToLandingPageTo = () => {
    this.props.navigation.history.push("")
  }
  toggleAccordion = () => {
    this.setState({ expanded: !this.state.expanded});
  }
  addRow = () => {
    this.setState({ taxPaidDetail: [...this.state.taxPaidDetail, { date: '', amount: '', subamount: '' }]});
  }
  toggleAccordionCapitalGains = () => {
    this.setState((prev) => ({
      expandedCapitalGains: !prev.expandedCapitalGains
    }));
  }
  handleChangeTaxDetail = (index: number, name: keyof Row, value: string) => {
    const regEx = /[^0-9\b]/
    if(regEx.test(value) || value.length > 10) {
      return
    }
    const newRows = [...this.state.taxPaidDetail];
    newRows[index][name] = value;

    this.setState({ taxPaidDetail: newRows });
  }
  toggleAccordionIncomeFromOtherSources = () => {
    this.setState((prev) => ({
      expandedIncomeFromOtherSources: !prev.expandedIncomeFromOtherSources
    }));
  }
  toggleAccordionDeductions = () => {
    this.setState((prev) => ({
      expandedDeductions: !prev.expandedDeductions
    }));
  }
  handleRadioChange = (event: { target: { value: string } }) => {
    const { value } = event.target
    this.setState({ isResident: value })
  }

  handleChangeCapitalGains = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const selectedValue = event.target.value;
    const regEx = /[^0-9\b]/

    if(regEx.test(selectedValue) || selectedValue.length > 10) {
      return
    }

    switch (name) {
      // capital Gains values 1
      case "short_term_capital_gains_value1":
        this.setState({ short_term_capital_gains_value1: selectedValue });
        break;
      case "short_term_capital_gains_value2":
        this.setState({ short_term_capital_gains_value2: selectedValue });
        break;
      case "short_term_capital_gains_value3":
        this.setState({ short_term_capital_gains_value3: selectedValue });
        break;
      case "short_term_capital_gains_value4":
        this.setState({ short_term_capital_gains_value4: selectedValue });
        break;
  
      // capital Gains values 2
      case "stcgCovered1":
        this.setState({ stcgCovered1: selectedValue });
        break;
      case "stcgCovered2":
        this.setState({ stcgCovered2: selectedValue });
        break;
      case "stcgCovered3":
        this.setState({ stcgCovered3: selectedValue });
        break;
      case "stcgCovered4":
        this.setState({ stcgCovered4: selectedValue });
        break;
  
      // capital gains values 3
      case "long_term_capital_gains_value1":
        this.setState({ long_term_capital_gains_value1: selectedValue });
        break;
      case "long_term_capital_gains_value2":
        this.setState({ long_term_capital_gains_value2: selectedValue });
        break;
      case "long_term_capital_gains_value3":
        this.setState({ long_term_capital_gains_value3: selectedValue });
        break;
      case "long_term_capital_gains_value4":
        this.setState({ long_term_capital_gains_value4: selectedValue });
        break;
  
      // capital gains values 4
      case "ltcg10_value1":
        this.setState({ ltcg10_value1: selectedValue });
        break;
      case "ltcg10_value2":
        this.setState({ ltcg10_value2: selectedValue });
        break;
      case "ltcg10_value3":
        this.setState({ ltcg10_value3: selectedValue });
        break;
      case "ltcg10_value4":
        this.setState({ ltcg10_value4: selectedValue });
        break;
  
      default:
        // Handle unknown fields if necessary
        break;
    }
  }

  handleChangeBottomValues = (value: any) => {
      this.setState({
        dueDate: value.toString()
      })
  }
  handleDateChange = (value: any) => {
    this.setState({
      actualDate: value.toString()
    })
}
  handleDateChangeValues = (index: number, name: keyof Row, date: any) => {    
    const newRows = [...this.state.taxPaidDetail];
    newRows[index][name] = date.toString();

    this.setState({ taxPaidDetail: newRows });
}
  handleChange2 = (event: any) => {
    const name = event.target.name;
    const selectedValue = event.target.value;
    const regEx = /[^0-9\b]/
    if(regEx.test(selectedValue) || selectedValue.length > 10) {
      return
    }
    else if (name === "winningLottery") {

      this.setState({
        winningLottery: selectedValue
      })
    }
    else if (name === "winningLottery2") {

      this.setState({
        winningLottery2: selectedValue
      })
    }

  }
  handleDeductionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const selectedValue = event.target.value;
     const regEx = /[^0-9\b]/

    if(regEx.test(selectedValue) || selectedValue.length > 10) {
      return
    }
    switch (name) {
    case "LiftInsurancePremiumPaid":
      this.setState({ LiftInsurancePremiumPaid: selectedValue });
      break;
    case "PaymentForAnnuityPlan":
      this.setState({ PaymentForAnnuityPlan: selectedValue });
      break;
    case "ContributionTowardProvidentFundPPF":
      this.setState({ ContributionTowardProvidentFundPPF: selectedValue });
      break;
    case "InvestmentInNSC":
      this.setState({ InvestmentInNSC: selectedValue });
      break;
    case "ContributionTowardsULIP":
      this.setState({ ContributionTowardsULIP: selectedValue });
      break;
    case "ContributionTowardsUTI":
      this.setState({ ContributionTowardsUTI: selectedValue });
      break;
    case "Re_PaymentOfHousingLoan":
      this.setState({ Re_PaymentOfHousingLoan: selectedValue });
      break;
    case "TuitionFees":
      this.setState({ TuitionFees: selectedValue });
      break;
    case "FixedDeposit":
      this.setState({ FixedDeposit: selectedValue });
      break;
    case "ContributionTowardsNPF":
      this.setState({ ContributionTowardsNPF: selectedValue });
      break;
    case "EmployeeContributionNPS":
      this.setState({ EmployeeContributionNPS: selectedValue });
      break;
    case "AdditionalContributionNPS":
      this.setState({ AdditionalContributionNPS: selectedValue });
      break;
    case "EmployerContributionNPS":
      this.setState({ EmployerContributionNPS: selectedValue });
      break;
    case "InfrastructureBonds":
      this.setState({ InfrastructureBonds: selectedValue });
      break;
    default:
      break;
  }
  }
  handleDeductionsChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const selectedValue = event.target.value;
    const regEx = /[^0-9\b]/

    if(regEx.test(selectedValue) || selectedValue.length > 10) {
      return
    }
    switch (name) {
      case "EquitySavingScheme":
        this.setState({ EquitySavingScheme: selectedValue });
        break;
      case "SukanyaSamridhiDeposit":
        this.setState({ SukanyaSamridhiDeposit: selectedValue });
        break;
      case "OtherDeductible80C":
        this.setState({ OtherDeductible80C: selectedValue });
        break;
      case "TotaInsideDeduction":
        this.setState({ TotaInsideDeduction: selectedValue });
        break;
      case "MediClaimPremium":
        this.setState({ MediClaimPremium: selectedValue });
        break;
      case "MedicalTreatmentPayment":
        this.setState({ MedicalTreatmentPayment: selectedValue });
        break;
      case "Donations80G":
        this.setState({ Donations80G: selectedValue });
        break;
      case "DeductionForMaintenance":
        this.setState({ DeductionForMaintenance: selectedValue });
        break;
      case "LoanForHigherEducation":
        this.setState({ LoanForHigherEducation: selectedValue });
        break;
      case "LoanForResidentialHouse":
        this.setState({ LoanForResidentialHouse: selectedValue });
        break;
      case "DisabilityDeduction":
        this.setState({ DisabilityDeduction: selectedValue });
        break;
      case "InterestOnSavings":
        this.setState({ InterestOnSavings: selectedValue });
        break;
      case "AgnipathSchemeContribution":
        this.setState({ AgnipathSchemeContribution: selectedValue });
        break;
      case "OtherDeductions":
        this.setState({ OtherDeductions: selectedValue });
        break;
      default:
        break;
    }
  }
  handleChangeSelectDropDown = (event: any) => {
    const name = event.target.name;
    const selectedValue = event.target.value;
    if (name === "AssessmentYear") {
      this.setState({
        assessmentYear: selectedValue,
        assessmentYearError: false
      })
    }
    else if (name === "TaxPayer") {
      this.setState({
        taxPayer: selectedValue,
        taxPayerError: false
      })
    }

    else if (name === "Gender") {
      this.setState({
        gender: selectedValue,
        genderError: false
      })
    }

    else if (name === "ResidentStatus") {
      this.setState({
        residentialStatus: selectedValue,
        residentialStatusError: false
      })
    }

  }
  handleSelectChange= (event: any) => {
    const { name, value } = event.target;
  
    this.setState({
      [name]: value
    }as unknown as Pick<S, keyof S> );
  }
  handleChangeIncomeFromOtherSource = (event: any) => {
    const name = event.target.name;
    const selectedValue = event.target.value;
    const regEx = /[^0-9\b]/

    if(regEx.test(selectedValue) || selectedValue.length > 10) {
      return
    }
    if (name === "interest") {


      this.setState({ interest: event.target.value });

    }
    else if (name === "commision") {
      this.setState({
        commision: selectedValue
      })
    }
    else if (name === "crossword") {
      this.setState({
        crossword: selectedValue
      })
    }
  }
  handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const selectedValue = event.target.value;

    const regEx = /[^0-9\b]/

    if(regEx.test(selectedValue) || selectedValue.length > 10) {
      return
    }
    switch (name) {
      case "incomeFromSalary":
        this.setState({
          incomeFromSalary: selectedValue,
          incomeFromSalaryError: false,
        });
        break;
      case "incomeHouseProperty":
        this.setState({
          incomeHouseProperty: selectedValue,
        });
        break;
      case "capitalGains":
        this.setState({
          capitalGains: selectedValue,
        });
        break;
      case "incomeFromOtherSource":
        this.setState({
          incomeFromOtherSource: selectedValue,
        });
        break;
      case "profitAndGains":
        this.setState({
          profitAndGains: selectedValue,
        });
        break;
      case "agricultureIncome":
        this.setState({
          agricultureIncome: selectedValue,
        });
        break;
      case "deduction":
        this.setState({
          deduction: selectedValue,
        });
        break;
      case "netTaxableIncome":
        this.setState({
          netTaxableIncome: selectedValue,
        });
        break;
      case "normalRate1":
        this.setState({
          normalRate1: selectedValue,
        });
        break;
      case "normalRate2":
        this.setState({
          normalRate2: selectedValue,
        });
        break;
      case "shortTermCapitalGains":
        this.setState({
          shortTermCapitalGains: selectedValue,
        });
        break;
      case "shortTermCapitalGains2":
        this.setState({
          shortTermCapitalGains2: selectedValue,
        });
        break;
      case "longTernCapitalGains":
        this.setState({
          longTernCapitalGains: selectedValue,
        });
        break;
      case "longTernCapitalGains2":
        this.setState({
          longTernCapitalGains2: selectedValue,
        });
        break;
      case "longTermAt10":
        this.setState({
          longTermAt10: selectedValue,
        });
        break;
      case "longTermAt102":
        this.setState({
          longTermAt102: selectedValue,
        });
        break;
      case "incomeTax":
        this.setState({
          incomeTax: selectedValue,
        });
        break;
      case "surcharge":
        this.setState({
          surcharge: selectedValue,
        });
        break;
      case "educationCess":
        this.setState({
          educationCess: selectedValue,
        });
        break;
      case "secondaryAndHighEducationCesss":
        this.setState({
          secondaryAndHighEducationCesss: selectedValue,
        });
        break;
      case "totalTaxLiabitlity":
        this.setState({
          totalTaxLiabitlity: selectedValue,
        });
        break;
      case "relief":
        this.setState({
          relief: selectedValue,
        });
        break;
      case "tds":
        this.setState({
          tds: selectedValue,
        });
        break;
      case "A234":
        this.setState({
          A234: selectedValue,
        });
        break;
      case "B234":
        this.setState({
          B234: selectedValue,
        });
        break;
      case "C234":
        this.setState({
          C234: selectedValue,
        });
        break;
      default:
        break;
    }
  }
  handleChange3 = (event: any) => {
    const name = event.target.name;
    const selectedValue = event.target.value;

    const regEx = /[^0-9\b]/

    if(regEx.test(selectedValue) || selectedValue.length > 10) {
      return
    }
    if (name === "InterestOnHousing") {

      this.setState({
        interestOnHousing: selectedValue
      })
    }
    else if (name === "test1") {

      this.setState({
        interestOnHousingLoan: selectedValue
      })
    }
    //extra
  }



  handleChange = (event: any) => {
    const name = event.target.name;
    const selectedValue = event.target.value;

    const regEx = /[^0-9\b]/

    if(regEx.test(selectedValue) || selectedValue.length > 10) {
      return
    }
    if (name === "incomeFromSelfHousePropery") {

      this.setState({
        incomeFromSelfHousePropery: selectedValue
      })
    }
    else if (name === "annualLetable") {

      this.setState({
        annualLetable: selectedValue
      })
    }
    else if (name === "municipalTaxPaid") {

      this.setState({
        municipalTaxPaid: selectedValue
      })
    }
    else if (name === "unrealisedRent") {

      this.setState({
        unrealisedRent: selectedValue
      })
    }
    else if (name === "netAnnualValue") {

      this.setState({
        netAnnualValue: selectedValue
      })
    }
    else if (name === "standardDeduction") {

      this.setState({
        standardDeduction: selectedValue
      })
    }

    else if (name === "incomeFromLetoutHouseProperty") {

      this.setState({
        incomeFromLetoutHouseProperty: selectedValue
      })
    }
    else if (name === "deduction") {

      this.setState({
        deduction: selectedValue
      })
    }
  }


  handleHRA_Change2 = (event: any) => {
    const { name, value } = event.target;
    
  const isDecimal = (val: string) => /^(\d+(\.\d*)?|\.\d+)?$/.test(val);
  const decimalFields = [
      "HRA_basicSalary",
      "HRA_DAFormingPartOfSalary",
      "HRA_CommissionTurnoverAchieved",
      "HRA_HraRecieved",
      "HRA_RentPaid",
      "YOS",
      "saleC",
      "transferExp",
      "COA",
      "COI",
      "TSV",
      "totalCost",
      "saleValue",
      "purchasedVal",
  
  ];
  const isPartialFourDigitYear = (val: string) => val === "" || /^(\d{1,4})$/.test(val);
  if (decimalFields.includes(name) && !isDecimal(value)) {
    return; 
  }
  if (name === "HRA_CommissionTurnoverAchieved" && Number(value) > 100) {
    return;
  }
  if (name === "YOS" && !isPartialFourDigitYear(value)) {
    return;
  }
  this.setState({
    [name]: value,
  }as unknown as Pick<S, keyof S> );
  }
  handleHRA_CheckboxChange = (event: any) => {
    this.setState((prev) => ({
      HRA_residingInMetro: !prev.HRA_residingInMetro
    }));
  }
  handleTextMessage = () => {
    this.setState({ openStatus: false })
  }
  handleReset = () => {
    this.setState({
      assessmentYearError: false,
      assessmentYear: "",
      taxPayerError: false,
      taxPayer: "",
      genderError: false,
      gender: "",
      residentialStatusError: false,
      residentialStatus: "",
      incomeFromSalary: "",
      incomeFromSalaryError: false,
      interestOnHousing: "",
      incomeHouseProperty: "",
      annualLetable: "",
      incomeFromSelfHousePropery: "",
      unrealisedRent: "",
      municipalTaxPaid: "",
      standardDeduction: "",
      netAnnualValue: "",

      capitalGains: "",
      incomeFromOtherSource: "",
      incomeFromLetoutHouseProperty: "",
      agricultureIncome: "",
      profitAndGains: "",
      netTaxableIncome: "",
      deduction: "",
      normalRate2: "",
      normalRate1: "",
      shortTermCapitalGains2: "",
      shortTermCapitalGains: "",
      longTernCapitalGains2: "",
      longTernCapitalGains: "",
      longTermAt102: "",
      longTermAt10: "",
      winningLottery2: "",
      winningLottery: "",
      incomeTax: "",
      educationCess: "",
      surcharge: "",
      totalTaxLiabitlity: "",
      secondaryAndHighEducationCesss: "",
      actualDate: "",
      dueDate: "",
      tds: "",
      relief: "",
      taxPaidDetail: [{date: '', amount: '', subamount: ''}],
      B234: "",
      A234: "",
      C234: "",
      isResident: "",
      interestOnHousingLoan: "",
      HRA_DAFormingPartOfSalary: "",
      HRA_basicSalary: "",
      HRA_CommissionTurnoverAchieved: "",
      HRA_RentPaid: "",
      HRA_HraRecieved: "",
      HRA_residingInMetro: false,
      HRA_ExemptedHouseRent: "",
      HRA_TaxableHouseRent: "",
      short_term_capital_gains_value1: "",
      short_term_capital_gains_value2: "",
      short_term_capital_gains_value3: "",
      short_term_capital_gains_value4: "",
      stcgCovered1: "",
      stcgCovered2: "",
      stcgCovered3: "",
      stcgCovered4: "",
      long_term_capital_gains_value1: "",
      long_term_capital_gains_value2: "",
      long_term_capital_gains_value3: "",
      long_term_capital_gains_value4: "",
      ltcg10_value1: "",
      ltcg10_value2: "",
      ltcg10_value3: "",
      ltcg10_value4: "",
      interest: "",
      commision: "",
      crossword: "",
      PaymentForAnnuityPlan: "",
      LiftInsurancePremiumPaid: "",
      InvestmentInNSC: "",
      ContributionTowardProvidentFundPPF: "",
      ContributionTowardsUTI: "",
      ContributionTowardsULIP: "",
      TuitionFees: "",
      Re_PaymentOfHousingLoan: "",
      ContributionTowardsNPF: "",
      FixedDeposit: "",
      AdditionalContributionNPS: "",
      EmployeeContributionNPS: "",
      InfrastructureBonds: "",
      EmployerContributionNPS: "",
      SukanyaSamridhiDeposit: '',
      EquitySavingScheme: "",
      TotaInsideDeduction: "",
      OtherDeductible80C: "",
      MedicalTreatmentPayment: "",
      MediClaimPremium: "",
      DeductionForMaintenance: "",
      Donations80G: "",
      LoanForResidentialHouse: "",
      LoanForHigherEducation: "",
      InterestOnSavings: "",
      DisabilityDeduction: "",
      OtherDeductions: "",
      AgnipathSchemeContribution: "",
      YOS:"",
      saleC:"", 
      transferExp:"",
      COA:"",
      COI:"",
      assessedTax:"",
      TSV:"",
      totalCost:"",
      saleValue:"",
      purchasedVal:"",
      periodOfHolding:"",
      
    })
  }
  handleFormSubmit = () => {
    if(this.state.assessmentYear === "") {
      this.setState({ assessmentYearError: true })
      window.scrollTo(0, 0)
    } 
    if(this.state.taxPayer === "") {
      this.setState({ taxPayerError: true })
      window.scrollTo(0, 0)
    } 
    if(this.state.gender === "") {
      this.setState({ genderError: true })
      window.scrollTo(0, 0)
    } 
    if(this.state.residentialStatus === "") {
      this.setState({ residentialStatusError: true })
      window.scrollTo(0, 0)
    } 
    if(this.state.incomeFromSalary === "") {
      this.setState({ incomeFromSalaryError: true })
      window.scrollTo(0, 0)
    } 
    
    else {
      this.setState({ openStatus: true })
    }
  }

  handlenavigation = () =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "TaxCalculator");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
  }

  handleRadioChangePeriod = (value:string,name:keyof S) => {
    this.setState({
      ...this.state,
      [name]:value as unknown as Pick<S, keyof S>
    })
  }

  handleCalculate = () => {
    this.setState({
      assessmentYear:this.state.assessmentYear,
      taxPayer:this.state.taxPayer,
      gender:this.state.gender
    })
  }

  // Customizable Area End
}
