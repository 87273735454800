import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
//Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End
export const configJSON = require("./config");
export const baseURL = require("../../../framework/src/config.js").baseURL;

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ToDoListType {
  feature_name: string,
  status: string,
}

export interface ToDoListAttributes {
  general_information: ToDoListType[],
  salary: ToDoListType[],
  house_property: ToDoListType[],
  deduction: ToDoListType[],
}

export interface S {
  // Customizable Area Start
  selected: {
    [keys: string]: unknown;
  };
  selectedValues: any;
  userPancards: {
    id: string;
    attributes: {
      profile: {
        attributes: {
          pan_number: string;
          name: string;
        };
      };
    };
  }[],
  assismentYear: {
    id: number,
    assessment_year: string,
    start_year: number,
    end_year: number,
    is_primary: boolean,
    created_at: string,
    updated_at: string
  }[],
  primaryAssismentYear: any
  year: any,
  pan: any,
  paymentflag:boolean,
  modalstate:string,
  ITRotp:string
  toDoList: {attributes: ToDoListAttributes}[],
  filterStatus: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  dashboardpan: string = ""
  pancardPrimary: string = ""
  assismentYearID: string = ""
  getToDoListApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      // Customizable Area Start
      selected: {
        panCard: "10",
        financialYear: "2323"
      },
      selectedValues: {},
      userPancards: [],
      assismentYear: [],
      primaryAssismentYear: "",
      pan: "",
      year: "",
      paymentflag:false,
      modalstate:"1",
      ITRotp:"",
      toDoList: [],
      filterStatus: "pending",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    // Customizable Area Start

    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (this.dashboardpan === responseData) {
      this.handleUserPancards(successMessage);

    } else if (this.pancardPrimary === responseData) { window.location.reload() }

    else if (this.assismentYearID === responseData) {
      if (responseData) {
        this.setState({
          assismentYear: successMessage
        })
      }
    }

    else if(this.getToDoListApiCallId === responseData){
      
      if(successMessage && successMessage.data){
        this.setState({
          toDoList: [successMessage.data]
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    await this.userDashboard()
    await this.getAssismentYearList()
    this.getToDoListData();
    // Customizable Area End
  }
  handleSelector = (value: any) => {
    this.setState(
      () => ({ pan: value }),
    )
    this.userPrimary()
  };
  handleSelectAssismentYear = (year: string) => {
    this.setState({
      year: year
    })
    this.userPrimary()
  }
  handleUserPancards = (apiResponse: {
    data: [
      {
        id: string;
        attributes: {
          profile: {
            attributes: {
              pan_number: string;
              name: string;
            };
          },
          assessment_year: {
            assessment_year: string
          }
        }
      }];
  }) => {
    if (apiResponse && apiResponse.data) {
      this.setState({
        userPancards: apiResponse.data,
        selectedValues: apiResponse.data[0],
        primaryAssismentYear: apiResponse.data[0],
        pan: apiResponse.data[0].attributes.profile.attributes.pan_number,
        year: apiResponse.data[0].attributes.assessment_year.assessment_year
      });
    }
  }
  ///pancard get details ///
  userDashboard = async () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": await getStorageData(configJSON.authToken)

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.dashboardpan = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.panCardEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getAssismentYearList = async () => {

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": await getStorageData(configJSON.authToken)

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.assismentYearID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assismentYearEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  ///pancard get details///
  userPrimary = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pancardPrimary = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panCardEndpoint}/set_primary_pan?pan_profile=${this.state.pan}&assessment_year=${this.state.year}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleNavigateToAddPan = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileBasicBlock");

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  handleModalOpen = () =>{
    this.setState({paymentflag:true})
  }

  handleModalClose = ()=>{
    this.setState({paymentflag:false,modalstate:"1"})
  }
  handleModalState = (state:string) =>{
    if(state == "loading"){
      this.setState({
        modalstate:"loading"
      })
      setTimeout(() => {
        this.setState({
          modalstate:"3"
        })
      }, 1500);
    }
    else if(state == "loading 1"){
      this.setState({
        modalstate:"loading",
        ITRotp:""
      })
      setTimeout(() => {
        this.setState({
          modalstate:"4"
        })
      }, 2000);
    }
    else{
      this.setState({
        modalstate:state
      })
    }
  }

  downloadDetermineItr = async () => {
    let token = await getStorageData(configJSON.authToken)

    const downloadURL = `${baseURL}/${configJSON.determineItrEndPoint}?token=${token}`;
    const newWindow = window.open(downloadURL, '_blank');
  }

  getToDoListData = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": await getStorageData(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getToDoListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getToDoListEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  formatFeatureName = (featureName: string) => {
    return featureName.split(/[_\s]/).map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  handleStatusChange = (status: string) => {
    this.setState({ filterStatus: status });
  };

  // Customizable Area End
}
