import React, { ReactElement } from "react";
// Customizable Area Start
import DashboardController, { Props } from "./DashboardController.web";
import {
  Box,
  Grid,
  Typography,
  Container,
  Button,
  MenuItem,
  Select, Modal
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
//@ts-ignore
import { ReactComponent as DownloadIcon } from "../assets/downloadIcon.svg";
//@ts-ignore
import { ReactComponent as RedRoundWallet } from "../assets/red_round_wallet.svg";
//@ts-ignore
import { ReactComponent as PurpleRoundWallet } from "../assets/purple_round_wallet.svg";
//@ts-ignore
import { ReactComponent as OrangeRoundWallet } from "../assets/orange_round_wallet.svg";
//@ts-ignore
import { ReactComponent as RupeeIcon } from "../assets/rupee.svg";
//@ts-ignore
import { ReactComponent as Capital_gains } from "../assets/capital_gains.svg";
//@ts-ignore
import { ReactComponent as Deduction } from "../assets/deduction.svg";
//@ts-ignore
import { ReactComponent as General_information } from "../assets/general_information.svg";
//@ts-ignore
import { ReactComponent as House_property } from "../assets/house_property.svg";
//@ts-ignore
import { ReactComponent as Other_source } from "../assets/other_source.svg";
//@ts-ignore
import { ReactComponent as Tax_deduction } from "../assets/tax_deduction.svg";
//@ts-ignore
import { ReactComponent as Business_income } from "../assets/business_income.svg";
//@ts-ignore
import { ReactComponent as Salary } from "../assets/salary.svg";
//@ts-ignore
import { ReactComponent as Green_cloude } from "../assets/green_cloude.svg";
import { SpeedMeter, card, pockettaxprice,loader } from "./assets";
import { configJSON } from "./DashboardController";
import Header from "../../../components/src/Header.web";
import OTPInput from "../../../components/src/OTPInput.web";

// Customizable Area End

export default class DashboardBlock extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  comunSelector = (key: string, _option: any) => {
    return (
      <Select
        key={key}
        name={key}
        value={this.state.selectedValues.panNumber}
        onChange={this.handleSelector}
        IconComponent={DownloadIcon}
        displayEmpty
        className="selector"
        data-test-id={key + "pandata"}
        inputProps={{ "aria-label": "Without label" }}
      >

        {_option && _option.map((item:
          {
            id: string;
            attributes: {
              profile: {
                attributes: {
                  pan_number: string;
                  name: string;
                };
              };
            };
          }
        ) => {
          return (
            <MenuItem className="menutoggle" value={item.attributes.profile.attributes.pan_number} >
              PAN : {item.attributes.profile.attributes.pan_number}
              <Typography className="typostyle" style={{ marginLeft: "1em" }}>{item.attributes.profile.attributes.name}</Typography>
            </MenuItem>
          )
        })}

      </Select>
    );
  };

  renderLeftTopBar = (
    subTitle: string,
    income: string,
    increment: string,
    icon: ReactElement,
    backgroundColor: string,
    incrementcolor: string
  ) => {
    return (
      <Box
        component="div"
        sx={{
          boxSizing: "border-box",
          minWidth: { xs: "250px", sm: "270px", md: "30%", lg: "30%" },
          padding: "25px 20px",
          color: "#36403b",
          borderRadius: "15px",
          margin: "10px 0",
          position: "relative",
          overflow: "hidden",
          zIndex: 0
        }}
        style={{
          backgroundColor: backgroundColor
        }}
      >
        <Box component="div" className="gradiant1" />
        <Box component="div" className="gradiant2" />
        <Typography variant="subtitle1" className="top-icon">
          {icon}
          <Typography
            component="span"
            className="increment-div"
            style={{ backgroundColor: incrementcolor }}
          >
            {increment}
          </Typography>
        </Typography>
        <Typography variant="body2" className="title-top">
          {subTitle}
        </Typography>
        <Typography variant="body1" className="money-left">
          <RupeeIcon />
          &nbsp; {income}
        </Typography>
      </Box>
    );
  };

  renderLeftBottomBar = () => {
    return [
      {
        icon: <General_information />,
        title: "General Information",
        discription: "Personal details, Resident status and Other Information",
        amount: ""
      },
      {
        icon: <Salary />,
        title: "Salary",
        discription: "Salary from -Builder.Ai",
        amount: "12,05,673"
      },
      {
        icon: <House_property />,
        title: "House Property",
        discription: "Rental income from Aarohi 2BHK in Delhi",
        amount: "12,05,673"
      },
      {
        icon: <Business_income />,
        title: "Business Income",
        discription: "Profil/Loss from 'Shopify' ",
        amount: "12,05,673"
      },
      {
        icon: <Capital_gains />,
        title: "Capital Gains",
        discription:
          "Gains And losses from shares & securities sale of immovable propertoes and cryptocurrency ",
        amount: "12,05,673"
      },
      {
        icon: <Other_source />,
        title: "Income From Other Sources",
        discription:
          "Interest, Dividend, Exempt income or any other income not coverred above",
        amount: "12,05,673"
      },
      {
        icon: <Deduction />,
        title: "Deducations",
        discription:
          "Deductible expenditure such as insurance, investment and loan repayment",
        amount: "12,05,673"
      },
      {
        icon: <Tax_deduction />,
        title: "Tax Deducted/ Paid",
        discription:
          "TDS, TCS and Advance tax and any other tax payment made during the year",
        amount: "12,05,673"
      }
    ].map((item, key) => {
      return (
        <Box
          key={`${key + 0}`}
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className="bottom-block"
          sx={{
            boxSizing: "border-box",
            width: { xs: "270px", sm: "45%", md: "45%", lg: "30%" },
            padding: "20px",
            color: "#36403b",
            borderRadius: "15px",
            marginY: "10px",
            marginX: { xs: "auto", sm: "2.5%", md: "2.5%", lg: "1.666%" },
            position: "relative",
            overflow: "hidden",
            zIndex: 0
          }}
        >
          <Box
            component="div"
            display="flex"
            alignItems="center"
            sx={{ marginBottom: "14px", position: "relative" }}
            className="left-bottom-title"
          >
            <Typography variant="subtitle1" className="top-icon">
              {item.icon}
            </Typography>
            <Typography component="span">{item.title}</Typography>
          </Box>
          <Typography variant="body2" className="bottom-left-description">
            {item.discription}
          </Typography>
          <Typography variant="body1" className="money-left">
            <RupeeIcon />
            &nbsp; {item.amount}
          </Typography>
          <Green_cloude className="greenIcon" />
        </Box>
      );
    });
  };

  renderModalContent() {
    const { modalstate } = this.state;
  
    switch(modalstate) {
      case "1":
        return (
          <Grid className="parentContainer">
            <Grid item xs={12} md={5} className="imagewrapper">
              <img src={pockettaxprice} alt={configJSON.logoAlt} className="imageprice" />
            </Grid>
            <Grid item xs={12} md={6} className="imagepaper" style={{ maxWidth: '100%', marginTop: '40px' }}>
              <Typography className="textHeadingcolor">Hope you had a smooth experience.</Typography>
              <Typography className="textHeadingcolor">Please pay our fee to proceed with filing</Typography>
              <Box className="content">
                <Typography className="price">₹ 900</Typography>
              </Box>
              <Box className="button">
                <Button className="startFiling" variant="contained" data-test-id="button2" onClick={() => { this.handleModalState("2") }}>Proceed to Payment</Button>
              </Box>
            </Grid>
          </Grid>
        );
      case "2":
        return (
          <Grid className="parentContainer">
            <Grid item xs={12} md={5} className="imagewrapper">
              <img src={pockettaxprice} alt={configJSON.logoAlt} className="imageprice" />
            </Grid>
            <Grid item xs={12} md={6} className="imagepaper" style={{ maxWidth: '100%', marginTop: '40px' }}>
              <Typography className="payment-textHeading">Congratulations!</Typography>
              <Typography className="payment-text">Payment Successfully Completed</Typography>
              <Box className="payment-button">
                <Button className="verify-now" variant="contained" data-test-id="button-loading" onClick={() => { this.handleModalState("loading") }}>File and Verify Now</Button>
                <Button className="verify-later" variant="contained" data-test-id="button5" onClick={() => { this.handleModalState("5") }}>File and Verify Later</Button>
              </Box>
            </Grid>
          </Grid>
        );
      case "loading":
        return (
          <Grid className="loading-parentContainer">
            <Grid item xs={12} md={5} className="imagewrapper">
              <img src={loader} alt={configJSON.logoAlt} className="imageprice-loading" />
            </Grid>
            <Grid item xs={12} md={6} className="imagepaper" style={{ maxWidth: '100%', marginTop: '40px' }}>
              <Typography className="loading-textHeadingcolor">Please wait...</Typography>
              <Typography className="loading-textHeadingcolor">We are processing your return with the</Typography>
              <Typography className="loading-textHeadingcolor">Income Tax Servers</Typography>
            </Grid>
          </Grid>
        );
      case "3":
        return (
          <Grid className="otp-parentContainer">
            <Typography className="otp-textHeadingcolor">E-Verify your ITR</Typography>
            <Grid item xs={12} md={5} className="imagewrapper">
              <OTPInput
                otp={this.state.ITRotp}
                separator=""
                blocks={6}
                handleOtpChange={(e) => { this.setState({ ITRotp: e }) }}
              />
            </Grid>
            <Grid className="otp-imagewrapper">
              <Typography className="otp-textHeadingcolor">Didn't receive the Code? <span style={{color:"#337152"}}>Resend Code</span></Typography>
              <Typography className="otp-textHeadingcolor">0.00.30</Typography>
            </Grid>
            <Button className="verify-now" variant="contained" data-test-id="button-loading-1" onClick={() => { this.handleModalState("loading 1") }}>
              <Typography className="otp-textHeadingcolor">E-Verify ITR Now</Typography>
            </Button>
          </Grid>
        );
      case "4":
        return (
          <Grid className="parentContainer">
            <Grid item xs={12} md={5} className="imagewrapper">
              <img src={pockettaxprice} alt={configJSON.logoAlt} className="imageprice" />
            </Grid>
            <Grid item xs={12} md={6} className="imagepaper" style={{ maxWidth: '100%', marginTop: '40px' }}>
              <Typography className="payment-textHeading">Successful</Typography>
              <Typography className="itr-textHeadingcolor">Your ITR is filed and e-verified. You will receive an email confirmation along with the acknowledgment</Typography>
              <Box className="payment-button">
                <Button className="verify-now" variant="contained" data-test-id="button4" onClick={() => { this.handleModalState("1") }}>View ITR history</Button>
                <Button className="verify-later" variant="contained" data-test-id="go_back" onClick={this.handleModalClose}>View Tax Saving Tips</Button>
              </Box>
            </Grid>
          </Grid>
        );
      case "5":
        return (
          <Grid className="parentContainer">
            <Grid item xs={12} md={5} className="imagewrapper">
              <img src={pockettaxprice} alt={configJSON.logoAlt} className="imageprice" />
            </Grid>
            <Grid item xs={12} md={6} className="imagepaper" style={{ maxWidth: '100%', marginTop: '40px' }}>
              <Typography className="payment-textHeading">Payment Failed.</Typography>
              <Typography className="payment-text">Retry payment and complete filing your ITR.</Typography>
              <Box className="payment-button">
                <Button className="verify-now" variant="contained" data-test-id="button1" onClick={() => { this.handleModalState("1") }}>File and Verify Now</Button>
              </Box>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  }
  // Customizable Area End
  render() {

    // Customizable Area Start
    const filteredList = this.state.toDoList.filter((todo) => {
      const attributes = todo.attributes;

      return Object.values(attributes).some((value) => {
        const features = Array.isArray(value) ? value : [value];
        return features.some((feature) => feature.status === this.state.filterStatus);
      });
    });

    const GeneralInfo = { imageSrc: <General_information />, bgColor: '#ECFFE3' };
    const SalaryObj = { imageSrc: <Salary />, bgColor: '#DEFAEC' };
    const HouseProperty = { imageSrc: <House_property />, bgColor: '#E8F4FB' };
    const DeductionObj = { imageSrc: <Deduction />, bgColor: '#F9F1F4' };
    const CapitalGains = { imageSrc: <Capital_gains />, bgColor: '#F4EDF9' };
    const OtherSource = { imageSrc: <Other_source />, bgColor: '#FCE9E9' };
    const BusinessIncome = { imageSrc: <Business_income />, bgColor: '#E4E4FB' };


    const featureMapping: Record<string, { imageSrc: JSX.Element; bgColor: string }> = {
      general_information: GeneralInfo,
      form16: SalaryObj,
      "salary manual": SalaryObj,
      self_occupied: HouseProperty,
      rental: HouseProperty,
      deduction: DeductionObj,
      donation: DeductionObj,
      "Sale of Land and Building": CapitalGains,
      "VDA (Capital Gain Asset Statements)": CapitalGains,
      "VDA Manual (Digital Assets)": CapitalGains,
      "Sale of Shares/Securities": CapitalGains,
      "Manual Securities": CapitalGains,
      "Capital Gain Bonds": CapitalGains,
      "Pass Through Incomes": CapitalGains,
      "Reversal of Capital Gains": CapitalGains,
      "Sale of Other Assets": CapitalGains,
      "Interest Income": OtherSource,
      "Dividend Income": OtherSource,
      "Rent Income": OtherSource,
      "Other Income": OtherSource,
      "Exempt Income": OtherSource,
      "Professional Income (44ADAs)": BusinessIncome,
      "Business Income (44ADS)": BusinessIncome,
      "Futures & Options": BusinessIncome,
      "Intraday Trading": BusinessIncome,
      "Income from Partnership Firms": BusinessIncome,
      "Other Business Income": BusinessIncome,
      "Income from Playing of Trucks (44AE)": BusinessIncome,
      "Very Small Businesses": BusinessIncome,
      "Regular Business Income": BusinessIncome,
    };
    
    const getFeatureDetails = (featureName: string) => {
      return (
        featureMapping[featureName] || {
          imageSrc: <General_information />,
          bgColor: '#F9F1F4',
        }
      );
    };    
    

    return (
      <MainWrapper maxWidth={false}>
        <>
          <Grid container>
            <Header
              data-testid="header_test"
              active={true}
              panlist={this.state.userPancards}
              primaryPan={this.state.selectedValues}
              selectedYear={this.state.assismentYear}
              yearList={this.state.assismentYear}
              primaryAssismentYear={this.state.primaryAssismentYear}
              handleSetAssismentYear={this.handleSelectAssismentYear}
              handleSelector={this.handleSelector}
              handleNavigateToAddPan={this.handleNavigateToAddPan}
              isRegimeToggleVisible={true}
              downloadDetermineItr={this.downloadDetermineItr}
            />
            <Grid
              item
              md={8}
              lg={8}
              xl={8}
              sm={12}
              xs={12}
              component="section"
              className="left-bar"
            >

              <Box component="div" className="bottom-left">
                <Typography variant="h5" className="left-bottom-intro">
                  Confirm/verify information in each tile to complete your return{" "}
                </Typography>
                <Box
                  component="div"
                  className="bottom-sub-left"
                  display="flex"
                  flexWrap="wrap"
                >
                  {this.renderLeftBottomBar()}
                </Box>
                <Box
                  component="div"
                  display="flex"
                  sx={{
                    width: { xs: "100%", sm: "100%", md: "85%", lg: "60%" },
                    justifyContent: { md: "space-around" },
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    padding: "10px"
                  }}
                >
                  <Button variant="contained" className="proceed-btn" onClick={this.handleModalOpen}>
                    Proceed for filing
                  </Button>
                  <Button variant="contained" className="computation-btn">
                    View computation of income
                  </Button>

                </Box>

                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", border: "none" }}>
                  <Modal onClose={this.handleModalClose} open={this.state.paymentflag} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", border: "none" }}>
                    <ModalBox>
                       {this.renderModalContent()}
                    </ModalBox>
                  </Modal>
                </Box>
              </Box>
              <Box
                component="div"
                display="flex"
                flexWrap="wrap"
                justifyContent="space-around"
                className="top-left"
              >
                {this.renderLeftTopBar(
                  "Net Total Income",
                  "15,00,000",
                  "+15%",
                  <RedRoundWallet />,
                  "#C7F4C2",
                  "#196A0F"
                )}
                {this.renderLeftTopBar(
                  "Total Tax Liability",
                  "12,05,567",
                  "+10%",
                  <OrangeRoundWallet />,
                  "#FDDD8D",
                  "#8F6A0D"
                )}
                {this.renderLeftTopBar(
                  "Outstanding Tax",
                  "12,05,567",
                  "+15%",
                  <PurpleRoundWallet />,
                  "#BFB5FF",
                  "#5748B8"
                )}
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              lg={4}
              xl={4}
              xs={12}
              sm={12}
              component="section"
              className="right-bar"
            >
              <Box component="div" className="right-top">
                <img src={SpeedMeter} alt="speed" className="tempImg" />
              </Box>
              <Box component="div" className="right-bottom">
                <Typography variant="h6">{configJSON.todolist}</Typography>
                <Box className="button-arrangment">
                  <Button data-test-id='changeStatusPending' className={this.state.filterStatus === "pending" ? "pendingEnable"  : "pending"} onClick={() => this.handleStatusChange("pending")}>{configJSON.Pending}</Button>
                  <Button data-test-id='changeStatusComplete' className={this.state.filterStatus === "complete" ? "completeEnable"  : "complete"} onClick={() => this.handleStatusChange("complete")}>{configJSON.Complete}</Button>
                </Box>
                 {filteredList.map((todo) => {
                  const attributes = todo.attributes;
                  return (
                    <>
                      {Object.entries(attributes).map(([key, value]) => {
                        const features = Array.isArray(value) ? value : [value];

                        return features.filter((feature) => feature.status === this.state.filterStatus)
                          .map((feature, index) => {
                            const { imageSrc, bgColor } = getFeatureDetails(feature.feature_name);

                            return <Box className="card-data" key={`${key}-${index}`}>
                              <Box className="ul-list">
                              {imageSrc}
                                
                                <Typography className="ul-text">
                                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                </Typography>
                              </Box>
                              <Box style={{ paddingBottom: "1em" }}>
                                <Box className="ul-salary-bg" style={{backgroundColor: bgColor}}>
                                  <Typography component="span" className="ul-salary">
                                    {this.formatFeatureName(feature.feature_name)}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                        });
                      })}
                    </>
                  );
                })}
                
              </Box>
            </Grid>
          </Grid>
        </>
      </MainWrapper>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const MainWrapper = styled(Container)({
  width: "100%",

 
  "& .tempImg": {
    width: "-webkit-fill-available"
  },
  "& .top-bar": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    },
    "& .selector": {
      border: "none",
      backgroundColor: "#F4F6F5",
      marginInline: "10px",
      width: "40%",
      margin: "10px 0",
      padding: "5px 5px",
      borderRadius: "5px",
      "& .MuiSelect-icon": {
        marginInline: "8px"
      },
      "@media (max-width:600px)": {
        width: "45%",
        marginInline: "0px"
      }
    },
    "& .MuiSelect-selectMenu": {
      display: "flex",
      alignItems: "self-end",
      justifyContent: "space-between",
    },

    "@media (max-width:600px)": {
      justifyContent: "space-around"
    },
    "& .menutoggle": {
      color: "#36403B",
      fontFamily: "Nunito",
      fontSize: "17px",
      fontWeight: 700,
    },
    "& .typostyle": {
      color: "#36403B",
      fontFamily: "Nunito",
      fontSize: "11px",
      fontWeight: 600,
      marginRight: '2em',

    }
  },
  "& .bottom-left": {
    backgroundColor: "#F4F6F5",
    borderRadius: "16px",
    margin: "12px 0",
    "& .bottom-block": {
      backgroundColor: "#fff"
    },
    "& .computation-btn": {
      backgroundColor: "#FDDD8D",
      margin: "5px"
    },
    "& .proceed-btn": {
      color: "#fff",
      backgroundColor: "#347054",
      margin: "5px"
    },
    "& .greenIcon": {
      position: "absolute",
      right: 0,
      bottom: 0,
      zIndex: -1
    },
    "& .money-left": {
      display: "flex",
      alignItems: "center",
      fontSize: "25px",
      marginTop: "8px"
    },
    "& .top-icon": {
      display: "flex",
      justifyContent: "space-between",
      marginRight: "8px"
    },
    "& .bottom-left-description": {
      fontSize: "14px"
    }
  },
  "& .left-bar": {
    width: "100%"
  },
  "& .right-bar": {
    width: "100%"
  },
  "& .gradiant1": {
    position: "absolute",
    width: "141.3px",
    height: "174.5px",
    right: "-10px",
    top: "-60%",
    background:
      "linear-gradient(214.31deg, #FFFFFF 3.37%, rgba(255, 255, 255, 0) 57.96%)",
    opacity: 0.5,
    backdropFilter: "blur(2px)",
    borderRadius: "11.3036px",
    transform: "rotate(165deg)",
    zIndex: -1
  },
  "& .gradiant2": {
    position: "absolute",
    width: "141.3px",
    height: "174.5px",
    right: "-30px",
    top: "-50%",
    background:
      "linear-gradient(204.14deg, #FFFFFF 4.36%, rgba(255, 255, 255, 0) 67.01%)",
    opacity: 0.5,
    backdropFilter: "blur(2px)",
    borderRadius: "11.3036px",
    transform: "rotate(165deg)",
    zIndex: -1
  },
  "& .top-icon": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .increment-div": {
    color: "#fff",
    borderRadius: "10px",
    width: "55px",
    height: "29px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1
  },
  "& .money-left": {
    display: "flex",
    alignItems: "center",
    fontSize: "25px"
  },
  "& .title-top": {
    margin: "16px 0",
    fontSize: "16px"
  },
  "& .left-bottom-intro": {
    padding: "15px 20px"
  },
  "& .left-bottom-title:after": {
    content: '""',
    position: "absolute",
    bottom: "-5px",
    width: "75%",
    height: "1px",
    right: 0,
    backgroundColor: "#36403b8c"
  },
  " & .right-top": {
    margin: "12px 0",
    padding: '1em'
  },
  "& .right-bottom": {
    padding: ' 0 1em 1em'
  },
  "& .button-arrangment": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: '10px 0 40px 0',
  },
  "& .pending": {
    background: "lightyellow",
    width: "100%",
    color: "orange",
    borderRadius: "0.5em"
  },
  "& .complete": {
    background: "#DFFFD6",
    width: "100%",
    marginLeft: '1em',
    color: "#4CAF50",
    borderRadius: "0.5em"

  },
  '& .pendingEnable': {
    borderRadius: "0.5em",
    width: "100%",
    color: "black",
    background: "#FDDD8D",
  },
  '& .completeEnable': {
    width: "100%",
    borderRadius: "0.5em",
    marginLeft: '1em',
    color: "#fff",
    background: "#347152",
  },
  "& .determinaItr": {
    background: "#347152",
    width: "200px",
    height: '50px',
    marginRight: '1.5em',
    color: "#fff",
    borderRadius: "0.5em"
  },
  "& .card-data": {
    background: "#FFF",
    boxShadow: "4px 3px 12px 0px rgba(0, 0, 0, 0.12)",
    marginTop: "20px",
    borderRadius: "8px",

  },
  "& .ul-list": {
    display: "flex",
    borderRadius: "8px",
    alignItems: "center",
    padding: '1em',
    paddingBottom: '5px',
    paddingTop: "1em"
  },
  "& .ul-text": {
    color: "#000",
    fontSize: "13px",
    fontWeight: 400,
    fontStyle: "normal",
    marginLeft: '1.5em'
  },
  " & .ul-img": {
    width: "40px",
    height: "42px",
  },
  " & .ul-salary": {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",

  },
  "& .ul-salary-bg": {
    marginLeft: "4.7em",
    display: "flex",
    // background: "#DEFAEC",
    width: "fit-content",
    padding: '5px',
    borderRadius: "5px",
    alignItems: "center",
    justifyContent: "center",
  }
});

const ModalBox = styled(Grid)({
  "& .parentContainer": {
    padding:'30px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor:"White",
    flexDirection:"column",
    borderRadius:"10px",
    border:"none",
    height: "500px",
  width: "400px"
  },
  "& .loading-parentContainer": {
    padding:'30px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor:"White",
    flexDirection:"column",
    borderRadius:"10px",
    border:"none",
    height: "230px",
  width: "420px"
  },
  "& .otp-parentContainer": {
    padding:'30px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor:"#1A202C",
    flexDirection:"column",
    borderRadius:"10px",
    border:"none",
    height: "300px",
  width: "500px"
  },
  "& .imagepaper":{
    maxWidth: "100%",
    marginTop: "60px !important"
  },
  "& .imagewrapper": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .otp-imagewrapper": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap:"100px"
  },
 
  "& .imageprice": {
    width: "200px",
    height: "200px",
  },
  "& .imageprice-loading": {
    width: "250px",
    height: "50px",
  },
  "& .textHeadingcolor": {
    color: "#393D3A",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center",
  },
  "& .itr-textHeadingcolor": {
    color: "#393D3A",
    fontFamily: "Nunito",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
  },
  "& .otp-textHeadingcolor": {
    color: "white",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center",
  },
  "& .loading-textHeadingcolor": {
    color: "#393D3A",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 700,
    textAlign: "center",
  },
  "& .payment-textHeading" :{
    color: "#393D3A",
    fontFamily: "Nunito",
    fontSize: "22px",
    fontWeight: 700,
    textAlign: "center",
  },
  "& .payment-text":{
    color: "#393D3A",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center",
  },
  "& .priceAt": {
    color: "#252525",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "center",
    marginTop: "20px",
  },
  "& .price": {
    color: "#2B664B",
    fontFamily: "Nunito",
    fontSize: "30px",
    fontWeight: 700,
    textAlign: "center",
  },
  "& .startFiling": {
    width: "200px",
    background: "#337152",
    color: "#FFFFFF",
    "&:hover": {
      background: "#337152",
    },
    marginTop: "20px",
  },
  "& .verify-now": {
    width: "200px",
    background: "#337152",
    color: "#FFFFFF",
    "&:hover": {
      background: "#337152",
    },
    marginTop: "20px",
  },
  "& .verify-later": {
    width: "200px",
    background: "#FDDD8D",
    color: "black",
    "&:hover": {
      background: "#FDDD8D",
    },
  },
  "& .payNotNow": {
    color: "#337152",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center",
    marginTop: "10px",
  },
  "& .button": {
    display: "flex",
    justifyContent: "center",

    width:"100%"
  },
  "& .payment-button":{
    display: "flex",
    justifyContent: "center",
    flexDirection:"column",
    gap:"20px",
    alignItems:"center"
  },

  "& .content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
   marginTop:'20px'
  },
  "& .rupee": {
    width: "24px",
    height: "24px",
    marginRight: "5px",
  },
  "& .payLater": {
    marginTop: "10px",
    textAlign: "center",
  },
})

// Customizable Area End